
@font-face {
  font-family: 'tunespeak';
  src:
    url(/static/media/tunespeak.db1d278c.ttf) format('truetype'),
    url(/static/media/tunespeak.f7463788.woff) format('woff'),
    url(/static/media/tunespeak.ee7edd1b.svg#tunespeak) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tunespeak' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lock:before {
  content: "\E903";
}
.icon-lyrics:before {
  content: "\E907";
}
.icon-release:before {
  content: "\E902";
}
.icon-discount:before {
  content: "\E901";
}
.icon-share:before {
  content: "\E900";
}
.icon-i-message:before {
  content: "\E935";
}
.icon-fb-messenger:before {
  content: "\E936";
}
.icon-clipboard:before {
  content: "\E90F";
}
.icon-check:before {
  content: "\E90C";
}
.icon-facebook:before {
  content: "\E919";
}
.icon-twitter:before {
  content: "\E91B";
}
.icon-mail:before {
  content: "\E922";
}
.icon-circle-play:before {
  content: "\EA15";
}
.icon-loading:before {
  content: "\E914";
}
.icon-chevron-right:before {
  content: "\E90A";
}
.icon-youtube:before {
  content: "\E91E";
}
.icon-spotify:before {
  content: "\E91A";
}
.icon-instagram:before {
  content: "\E91C";
}
.icon-itunes:before {
  content: "\E958";
}
.icon-photo:before {
  content: "\E921";
}
.icon-music:before {
  content: "\E924";
}
.icon-album:before {
  content: "\E931";
}
.icon-video:before {
  content: "\E967";
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.icon-loading {
  display: inline-block;
  -webkit-animation: spin 2.25s linear infinite;
          animation: spin 2.25s linear infinite; }

.glyph {
  font-size: 16px;
  width: 15em;
  padding-bottom: 1em;
  margin-right: 4em;
  margin-bottom: 1em;
  float: left;
  overflow: hidden; }

.talign-right {
  text-align: right; }

.talign-center {
  text-align: center; }

.size1of2 {
  width: 50%; }

.size1of1 {
  width: 100%; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.hidden-true {
  display: none; }

