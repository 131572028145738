@font-face {
  font-family: 'tunespeak';
  src:
    url('fonts/tunespeak.ttf?yrsybn') format('truetype'),
    url('fonts/tunespeak.woff?yrsybn') format('woff'),
    url('fonts/tunespeak.svg?yrsybn#tunespeak') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'tunespeak' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lock:before {
  content: "\e903";
}
.icon-lyrics:before {
  content: "\e907";
}
.icon-release:before {
  content: "\e902";
}
.icon-discount:before {
  content: "\e901";
}
.icon-share:before {
  content: "\e900";
}
.icon-i-message:before {
  content: "\e935";
}
.icon-fb-messenger:before {
  content: "\e936";
}
.icon-clipboard:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e90c";
}
.icon-facebook:before {
  content: "\e919";
}
.icon-twitter:before {
  content: "\e91b";
}
.icon-mail:before {
  content: "\e922";
}
.icon-circle-play:before {
  content: "\ea15";
}
.icon-loading:before {
  content: "\e914";
}
.icon-chevron-right:before {
  content: "\e90a";
}
.icon-youtube:before {
  content: "\e91e";
}
.icon-spotify:before {
  content: "\e91a";
}
.icon-instagram:before {
  content: "\e91c";
}
.icon-itunes:before {
  content: "\e958";
}
.icon-photo:before {
  content: "\e921";
}
.icon-music:before {
  content: "\e924";
}
.icon-album:before {
  content: "\e931";
}
.icon-video:before {
  content: "\e967";
}
